import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import ModelList from './ModelList';

function NavigationBar() {
    return (
    <>
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="/" style={{ paddingLeft: '20px' }}>TX ADHD Hub</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/support-groups">Support Groups</Nav.Link>
                    <Nav.Link href="/licensed-psychiatrists">Licensed Psychiatrists</Nav.Link>
                    <Nav.Link href="/pharmacies">Pharmacies</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </>
    )
}
export default NavigationBar