import React from 'react';
import NavigationBar from './NavigationBar';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 

const SupportGroupTemplate = ({ supportGroupData }) => {
  const {
    Name: name,
    Description: description,
    'Meeting Location': meetingLocation,
    'Meeting Time': meetingTime,
    'Focus Group': focusGroup,
    Cost: cost,
    Host: hostName,
    'Contact Info': contactInfo,
    'External Link': externalLink,
    imageUrl,
    mapEmbed,
    'Pharmacy Connection': pharmacyConnection,
    'Psychiatrist Connection': psychiatristConnection
  } = supportGroupData;

  return (
    <>
      <NavigationBar />
      <Container className="mt-4">
        <Row>
          {/* Left: Support Group Image, Name, and Description */}
          <Col md={6}>
            <Card>
              <Card.Img variant="top" src={imageUrl} alt={name} />
              <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>
                  <h4>Description</h4>
                  {description}
                </Card.Text>
                {/* Embed Google Map */}
                <div className="mt-4">
                  <h4>Location:</h4>
                  <div className="embed-responsive embed-responsive-16by9" style={{ overflow: 'hidden' }}>
                    <div dangerouslySetInnerHTML={{ __html: mapEmbed }} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Right: Support Group Details */}
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>Contact Info:</h4>
                <Card.Text>{contactInfo}</Card.Text>

                <h4>Meeting Time:</h4>
                <Card.Text>{meetingTime}</Card.Text>

                <h4>Meeting Location:</h4>
                <Card.Text>{meetingLocation}</Card.Text>

                <h4>Focus Group:</h4>
                <Card.Text>{focusGroup}</Card.Text>

                <h4>Cost:</h4>
                <Card.Text>{cost}</Card.Text>

                <h4>Host Name:</h4>
                <Card.Text>{hostName}</Card.Text>

                <div className="mt-4">
                  <Button href={externalLink} target="_blank" rel="noopener noreferrer" variant="primary">
                    Visit Support Group Website
                  </Button>
                </div>

                <div className="mt-4">
                  <h4>Related Connections:</h4>
                  {pharmacyConnection && (
                    <Button as={Link} to={`/pharmacies/${pharmacyConnection}`} variant="secondary" className="me-2">
                      Related Pharmacy
                    </Button>
                  )}
                  {psychiatristConnection && (
                    <Button as={Link} to={`/licensed-psychiatrists/${psychiatristConnection}`} variant="secondary">
                      Related Psychiatrist
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

SupportGroupTemplate.propTypes = {
  supportGroupData: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
    'Meeting Location': PropTypes.string.isRequired,
    'Meeting Time': PropTypes.string.isRequired,
    'Focus Group': PropTypes.string.isRequired,
    Cost: PropTypes.string.isRequired,
    Host: PropTypes.string.isRequired,
    'Contact Info': PropTypes.string.isRequired,
    'External Link': PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    mapEmbed: PropTypes.string.isRequired,
    'Pharmacy Connection': PropTypes.string,
    'Psychiatrist Connection': PropTypes.string,
  }).isRequired,
};

export default SupportGroupTemplate;
