import React from 'react';
import { Container, Button, ListGroup, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavigationBar from './NavigationBar';

const Home = () => {
  const models = ['Support Groups', 'Licensed Psychiatrists', 'Pharmacies'];
  return (
    <>
      <NavigationBar />
      <Container fluid className="text-center p-5 my-5" style={{ backgroundColor: '#e9ecef', borderRadius: '0.3rem' }}>
        <h1>Welcome to TX ADHD Hub</h1>
        <p>Your resource for ADHD support and community engagement in Texas.</p>
        <Button variant="primary" className="mb-4" href="/about">About Us</Button>
        <Card className="mx-auto" style={{ maxWidth: '500px', borderRadius: '0.3rem' }}>
          <Card.Body>
            <Card.Title className="mb-4">Explore Our Resources</Card.Title>
            <ListGroup variant="flush">
              {models.map((model) => (
                <ListGroup.Item key={model}>
                  <Link to={`/${model.toLowerCase().replace(' ', '-')}`}>{model}</Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Home;