import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import axios from 'axios';

import lanceImage from '../images/lance.jpg';
import bhuvanImage from '../images/bhuvan.png';
import aneeshImage from '../images/aneesh.png';
import atillaImage from '../images/atilla.jpg';
import nikhilImage from '../images/nikhil.png';

const teamMembers = [
  {
    name: "Lance Zhang",
    username: "lancezhang04",
    role: "Backend",
    image: lanceImage,
  },
  {
    name: "Bhuvan Kannaeganti",
    username: "bhuvankann",
    role: "Full Stack",
    image: bhuvanImage,
  },
  {
    name: "Aneesh Singh",
    username: "FCAniche",
    role: "Frontend",
    image: aneeshImage,
  },
  {
    name: "Atilla Istami",
    username: "Kumatamo",
    role: "Frontend",
    image: atillaImage,
  },
  {
    name: "Nikhil Kalidasu",
    username: "srikarnikhil.kalidasu",
    role: "Full Stack",
    image: nikhilImage,
  },
];

function About() {
  const [stats, setStats] = useState({});

  useEffect(() => {
    // Fetch stats for each team member from Flask backend
    const fetchStats = async () => {
                try {
                  const response = await axios.post('https://txadhdhub.me/api/stats', {
                    teamMembers: teamMembers,
        });
        setStats(response.data);
      } catch (error) {
        console.error('Failed to fetch stats:', error);
      }
    };

    fetchStats();
  }, []);

  return (
      <>
        <NavigationBar />
        <Container className="mt-4">
          <h1>About TX ADHD Hub</h1>
          <p>
            TX ADHD Hub aims to provide resources, information, and support for individuals affected by ADHD in underserved communities in Texas.
            Our goal is to connect users with healthcare professionals, support groups, and pharmacies while fostering community engagement.
          </p>
          <h2>Data Sources & Tools</h2>
          <p>
            Our platform integrates data from multiple sources, such as CHADD's Professional Directory, DSHS Pharmacy List, and Google APIs, to provide
            a comprehensive resource for ADHD support.
          </p>
          <h2>Team Members</h2>
          <Row>
            {teamMembers.map((member, index) => (
                <Col key={index} md={4} className="mb-4 d-flex align-items-stretch">
                  <Card style={{ width: '100%', minHeight: '100%' }}>
                    <Card.Img
                        variant="top"
                        src={member.image}
                        alt={member.name}
                        style={{ height: '300px', objectFit: 'cover' }}
                    />
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>{member.name}</Card.Title>
                      <Card.Text>
                        Role: {member.role} <br />
                        Commits: {stats[member.username]?.commits || 'Loading...'} <br />
                        Open Issues: {stats[member.username]?.open_issues || 'Loading...'} <br />
                        Closed Issues: {stats[member.username]?.closed_issues || 'Loading...'} <br />
                        Unit Tests: {stats[member.username]?.tests || 'Loading...'}
                      </Card.Text>
                      <div className="mt-auto"></div>
                    </Card.Body>
                  </Card>
                </Col>
            ))}
          </Row>
          <h2>API Documentation</h2>
          <p>
            Our website's code is thoroughly documented on Postman, publicly available <a href="https://documenter.getpostman.com/view/15226336/2sAXxLAth6" target="_blank" rel="noreferrer">here</a>.
          </p>
        </Container>
      </>
  );
}

export default About;
