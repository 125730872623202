import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import parseCSV from './csvParser'
import PharmacyTemplate from './PharmacyTemplate'; // Import different templates
import PsychiatristTemplate from './PsychiatristTemplate';
import SupportGroupTemplate from './SupportGroupTemplate';

const GeneralInstance = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { model, id } = useParams(); // Get model (pharmacy, psychiatrist, support-groups) and id

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await parseCSV(model); // Fetch CSV based on the model
        const instance = data[id]; // Get the specific instance by index (id)
        setData(instance);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [model, id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!data) return <div>Instance not found</div>;

  // Render the appropriate template based on the model
  switch (model) {
    case 'pharmacies':
      return <PharmacyTemplate pharmacyData={data} />;
    case 'licensed-psychiatrists':
      return <PsychiatristTemplate psychiatristData={data} />;
    case 'support-groups':
      return <SupportGroupTemplate supportGroupData={data} />;
    default:
      return <div>Unknown model: {model}</div>;
  }
};

export default GeneralInstance;