import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery'; 
window.$ = $;
window.jQuery = $; // Make jQuery globally accessible
import NavigationBar from './NavigationBar';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const PharmacyTemplate = ({ pharmacyData }) => {
  const {
    Name: name,
    Location: address,
    'Contact Info': contactInfo,
    'Operating Hours (Mon-Fri)': operatingHoursMonFri,
    'Operating Hours (Sat)': operatingHoursSat,
    'Operating Hours (Sun)': operatingHoursSun,
    'External Link': externalLink,
    Ratings: rating,
    'Accepts Insurance': acceptsInsurance,
    imageUrl,
    'Map Embed': mapEmbed,
    'Psychiatrist Connection': psychiatristConnection,
    'Support Group Connection': supportGroupConnection,
    'Place ID': placeId //placeid from csv
  } = pharmacyData;

  // Load Google Places API script dynamically in useEffect
  useEffect(() => {
    const loadGooglePlacesScript = () => {
      const script2 = document.createElement('script');
      script2.src = '/js/google-places.js'; 
      script2.async = true;
      document.head.appendChild(script2);

      const script3 = document.createElement('script');
      script3.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
      script3.async = true;
      document.head.appendChild(script3);
    };

    const loadGoogleReviews = () => {
      if ($) {
        $("#google-reviews").googlePlaces({
          placeId: placeId,
          render: ['reviews'],
          min_rating: 0,
          max_rows: 5
        });
      }
    };

    loadGooglePlacesScript();

    // Delay to allow scripts to load before rendering reviews
    setTimeout(loadGoogleReviews, 1000);
  }, [placeId]);

  return (
    <>
      <NavigationBar />
      <Container className="mt-4">
        <Row>
            <Col md={6}>
            <Card>
              <Card.Img variant="top" src={imageUrl} alt={name} />
              <Card.Body>
                <Card.Title>{name}</Card.Title>
                      <div className="mt-4">
                  <h4>Location:</h4>
                  <div className="embed-responsive embed-responsive-16by9" style={{ overflow: 'hidden' }}>
                    <div dangerouslySetInnerHTML={{ __html: mapEmbed }} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

            <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h3>Rating: {rating}/5</h3>
                <Card.Text><strong>Contact Info:</strong> {contactInfo}</Card.Text>
                <Card.Text><strong>Address:</strong> {address}</Card.Text>
                <Card.Text><strong>Accepts Insurance:</strong> {acceptsInsurance ? "Yes" : "No"}</Card.Text>

                <h4>Operating Hours:</h4>
                <Card.Text>Mon-Fri: {operatingHoursMonFri}</Card.Text>
                <Card.Text>Sat: {operatingHoursSat}</Card.Text>
                <Card.Text>Sun: {operatingHoursSun}</Card.Text>

                <div className="mt-4">
                  <Button href={externalLink} target="_blank" rel="noopener noreferrer" variant="primary">
                    Visit Pharmacy Website
                  </Button>
                </div>

                      <div className="mt-4">
                  <h4>Related Connections:</h4>
                  {psychiatristConnection && (
                    <Button as={Link} to={`/licensed-psychiatrists/${psychiatristConnection}`} variant="secondary" className="me-2">
                      Related Psychiatrist
                    </Button>
                  )}
                  {supportGroupConnection && (
                    <Button as={Link} to={`/support-groups/${supportGroupConnection}`} variant="secondary">
                      Related Support Group
                    </Button>
                  )}
                </div>
                {/* Scrollable reviews section */}
                <div style={{ marginTop: '20px', maxHeight: '300px', overflowY: 'scroll', border: '1px solid black', padding: '10px' }}>
                  <h4>Google Reviews:</h4>
                  <div id="google-reviews"></div>
                </div>
              </Card.Body>
        </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

PharmacyTemplate.propTypes = {
  pharmacyData: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    'Image URL': PropTypes.string.isRequired,
    Location: PropTypes.string.isRequired,
    'Contact Info': PropTypes.string.isRequired,
    'Operating Hours (Mon-Fri)': PropTypes.string,
    'Operating Hours (Sat)': PropTypes.string,
    'Operating Hours (Sun)': PropTypes.string,
    'External Link': PropTypes.string.isRequired,
    Ratings: PropTypes.number.isRequired,
    'Accepts Insurance': PropTypes.bool.isRequired,
    'Map Embed': PropTypes.string.isRequired,
    'Psychiatrist Connection': PropTypes.string,
    'Support Group Connection': PropTypes.string,
    'Place ID': PropTypes.string.isRequired // Ensure Place ID is required
  }).isRequired,
};

export default PharmacyTemplate;
