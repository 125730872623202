// src/utils/csvParser.js
import Papa from 'papaparse';

const parseCSV = async (model) => {
  const modelToFileMap = {
    'support-groups': '/data/csv/Support_Group_Data.csv',
    'licensed-psychiatrists': '/data/csv/Psychiatrists_Data.csv',
    'pharmacies': '/data/csv/Pharmacy_Data.csv'
  };

  const filePath = modelToFileMap[model];

  if (!filePath) {
    throw new Error(`No CSV file found for model: ${model}`);
  }

  return new Promise((resolve, reject) => {
    Papa.parse(filePath, {
      download: true,
      header: true,
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject(error);
      }
    });
  });
};

export default parseCSV;
