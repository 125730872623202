import React from 'react';
import NavigationBar from './NavigationBar';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PsychiatristTemplate = ({ psychiatristData }) => {
  const {
    Name: name,
    Location: location,
    Specialty: specialty,
    'Types of Therapy': typesOfTherapy,
    'Contact Info': contactInfo,
    'Accepts Insurance': acceptsInsurance,
    Description: description,
    'External Link': externalLink,
    Specialization: specialization,
    imageUrl,
    mapEmbed,
    'Pharmacy Connection': pharmacyConnection,
    'Support Group Connection': supportGroupConnection
  } = psychiatristData;

  return (
    <>
      <NavigationBar />
      <Container className="mt-4">
        <Row>
          {/* Left: Psychiatrist Image, Name, and Description */}
          <Col md={6}>
            <Card>
              <Card.Img variant="top" src={imageUrl} alt={name} />
              <Card.Body>
                <Card.Title>{name}</Card.Title>
                <div className="mt-4">
                  <h4>Description</h4>
                  <Card.Text>{description}</Card.Text>
                </div>
                {/* Embed Google Map */}
                <div className="mt-4">
                  <h4>Location:</h4>
                  <div className="embed-responsive embed-responsive-16by9" style={{ overflow: 'hidden' }}>
                    <div dangerouslySetInnerHTML={{ __html: mapEmbed }} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Right: Psychiatrist Details */}
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>Contact Info:</h4>
                <Card.Text>{contactInfo}</Card.Text>

                <h4>Specialization:</h4>
                <Card.Text>{specialization}</Card.Text>

                <h4>Location:</h4>
                <Card.Text>{location}</Card.Text>

                <h4>Specialty:</h4>
                <Card.Text>{specialty}</Card.Text>

                <h4>Types of Therapy:</h4>
                <Card.Text>{typesOfTherapy || 'Not Available'}</Card.Text>

                <h4>Accepts Insurance:</h4>
                <Card.Text>{acceptsInsurance ? 'Yes' : 'No'}</Card.Text>

                <div className="mt-4">
                  <Button href={externalLink} target="_blank" rel="noopener noreferrer" variant="primary">
                    Visit Psychiatrist Website
                  </Button>
                </div>

                {/* Related connections */}
                <div className="mt-4">
                  <h4>Related Connections:</h4>
                  {pharmacyConnection && (
                    <Button as={Link} to={`/pharmacies/${pharmacyConnection}`} variant="secondary" className="me-2">
                      Related Pharmacy
                    </Button>
                  )}
                  {supportGroupConnection && (
                    <Button as={Link} to={`/support-groups/${supportGroupConnection}`} variant="secondary">
                      Related Support Group
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

PsychiatristTemplate.propTypes = {
  psychiatristData: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Location: PropTypes.string.isRequired,
    Specialty: PropTypes.string.isRequired,
    'Types of Therapy': PropTypes.string.isRequired,
    'Contact Info': PropTypes.string.isRequired,
    'Accepts Insurance': PropTypes.bool.isRequired,
    Description: PropTypes.string.isRequired,
    'External Link': PropTypes.string.isRequired,
    Specialization: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    mapEmbed: PropTypes.string.isRequired,
    'Pharmacy Connection': PropTypes.string,
    'Support Group Connection': PropTypes.string
  }).isRequired,
};

export default PsychiatristTemplate;
