import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const InstanceCard = ({ instance, modelType, index }) => {
  let modelKey;

  switch (modelType) {
    case 'pharmacies':
      modelKey = ["Ratings", "Operating Hours (Mon-Fri)", "Location", "Operating Hours (Sat)", "Accepts Insurance"];
      break;
    case 'licensed-psychiatrists':
      modelKey = ["Specialization", "Location", "Specialty", "Types of Therapy", "Accepts Insurance"];
      break;
    case 'support-groups':
      modelKey = ["Meeting Time", "Meeting Location", "Focus Group", "Cost", "Host"];
      break;
    default:
      modelKey = [];
      break;
  }

  return (
    <Link to={`/${modelType}/${index}`} style={{ textDecoration: 'none' }}>
      <Card style={{ width: '100%', minHeight: '100%' }}>
        <Card.Img
          variant="top"
          src={instance['imageUrl']}
          alt={instance["Name"]}
          style={{ height: '300px', objectFit: 'cover' }}
        />
        <Card.Body className="d-flex flex-column">
          <Card.Title>{instance["Name"]}</Card.Title>
          {modelKey.map((key, idx) => (
            <Card.Text key={idx}>
              {key}: {instance[key] || 'N/A'}
            </Card.Text>
          ))}
          <div className="mt-auto"></div>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default InstanceCard;