import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import parseCSV from './csvParser';
import NavigationBar from './NavigationBar';
import InstanceCard from './InstanceCard.js';
import { Container, Row, Col } from 'react-bootstrap';

const ModelList = () => {
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { model } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await parseCSV(model);
        console.log('Parsed CSV data:', data);
        // Filter out empty instances
        const filteredData = data.filter(instance => {
          return Object.values(instance).some(value => value.trim() !== '');
        });
        console.log('Filtered data:', filteredData);
        setInstances(filteredData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [model]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (instances.length === 0) return <div>No data found for this model.</div>;

  console.log('Instances state:', instances);
  console.log(model);
  const title = model.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  let modelDescription;
  switch (model) {
    case 'pharmacies':
      modelDescription = "Here you can find available pharmacies in Texas";
      break;
    case 'licensed-psychiatrists':
      modelDescription = "Here you can find licensed psychiatrists who specialize in ADHD who are available for counseling"
      break;
    case 'support-groups':
      modelDescription = "Here you can find support groups where you and people in a similar situation can provide information and help to each other."
      break;
  }

  return (
    <div>
      <NavigationBar />
      <Container className="mt-4">
        <h1 className="text-center">{title}</h1>
        <p className="text-center">{modelDescription}</p>
        <Row>
          {instances.slice(0, 3).map((instance, index) => (
            <Col key={index} md={4} className="mb-4 d-flex align-items-stretch">
              <InstanceCard instance={instance} modelType={model} index={index} />
            </Col>
          ))}
        </Row>
        <p>Total: {instances.length}</p>
      </Container>
    </div>
  );
};

export default ModelList;